



























import { Vue, Component, Watch } from 'vue-property-decorator';
import clone from 'clone';
import { API } from '@/types';
import { apiGETMulti, apiGETSingle } from '@/api';
import { Socket } from 'vue-socket.io-extended';
import { rootModule } from '@/store';

@Component
export default class extends Vue {
  event: API.Events.GET | null = null;
  commercialLog: API.Commercials.Logs.GET[] = [];
  streamData: API.AuthorisedChannels.StreamData.GET['data'] | null = null;
  currentTime = Date.now();

  get selectedEvent(): number | null { return rootModule.selectedEvent; }

  get channelId(): string | null {
    return this.event?.mainChannelId ?? null;
  }

  // TODO: Handle errors!
  async loadEventAPIData(): Promise<void> {
    let data: API.Events.GET | null = null;
    if (this.selectedEvent !== null) ({ data } = await apiGETSingle('events', this.selectedEvent));
    Vue.set(this, 'event', data);
  }

  // TODO: Handle errors!
  async loadCommercialLogAPIData(): Promise<void> {
    const { data } = await apiGETMulti('commercialLog', {
      max: 20, // TODO: Actually paginate if needed.
      // TODO: Filter by channel ID when available server-side.
    });
    Vue.set(this, 'commercialLog', data);
  }

  // TODO: Handle errors!
  async loadStreamDataAPIData(): Promise<void> {
    let data: API.AuthorisedChannels.StreamData.GET | null = null;
    if (this.channelId !== null) ({ data } = await apiGETSingle('streamData', this.channelId));
    Vue.set(this, 'streamData', data?.data ?? null);
  }

  @Socket('eventModified')
  socketEventModified(newVal: API.Events.GET | null, oldVal: API.Events.GET | null): void {
    if (oldVal?.id === this.selectedEvent) {
      if (!newVal) Vue.set(this, 'event', null);
      else Vue.set(this, 'event', { ...this.event, ...newVal });
    } else if (!oldVal && newVal?.id === this.selectedEvent) {
      Vue.set(this, 'event', { ...this.event, ...newVal });
    }
  }

  @Socket('commercialLogged')
  socketCommercialLogged(val: API.Commercials.Logs.GET): void {
    this.commercialLog.unshift(val);
    if (this.commercialLog.length > 20) this.commercialLog.length = 20;
  }

  @Socket('authorisedChannelStreamDataModified')
  socketStreamDataModified(val: API.AuthorisedChannels.StreamData.GET): void {
    if (val.id === this.channelId) Vue.set(this, 'streamData', val);
  }

  // Logs for specific channel sorted newest first.
  get logsForChan(): API.Commercials.Logs.GET[] {
    if (!this.channelId) return [];
    return clone(this.commercialLog)
      .filter((c) => this.channelId && c.channelIds.includes(this.channelId))
      .sort((a, b) => Date.parse(b.timestamp) - Date.parse(a.timestamp));
  }

  get lastHourLogs(): API.Commercials.Logs.GET[] {
    const hourAgo = this.currentTime - (60 * 60 * 1000);
    return this.logsForChan.filter((c) => Date.parse(c.timestamp) >= hourAgo);
  }

  get lastHourAmount(): number {
    return this.lastHourLogs.reduce((prev, curr) => prev + curr.length, 0);
  }

  get seperator(): string {
    return 'thin solid rgba(255, 255, 255, 0.12)';
  }

  @Watch('selectedEvent', { immediate: true })
  async onSelectedEventChange(): Promise<void> {
    await this.loadEventAPIData();
    await this.loadCommercialLogAPIData();
    await this.loadStreamDataAPIData();
  }

  async created(): Promise<void> {
    window.setInterval(() => { this.currentTime = Date.now(); }, 1000);
  }
}
