var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:{
    Item: _vm.isActive,
    'light-green darken-3': _vm.isActive,
    'grey darken-3': !_vm.isActive,
    'd-flex': true,
    'flex-column': true,
    'justify-space-between': true,
  },style:({
    'min-width': '1%',
    flex: '1',
    'overflow-wrap': 'break-word',
    'text-align': 'center',
    padding: '3px 7px',
  })},[_c('div',[_vm._v(" "+_vm._s(_vm.name)+" ")]),_c('div',{staticClass:"font-weight-bold"},[(_vm.index === 0)?[_vm._v(" Focus ")]:[_vm._v(" P"+_vm._s(_vm.index + 1)+" ")]],2)])}
var staticRenderFns = []

export { render, staticRenderFns }