var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-3 pa-3 mb-0"},[_c('draggable',{staticClass:"d-flex pa-0",style:({
      width: '100%',
      padding: '10px',
      overflow: 'hidden',
    }),attrs:{"draggable":".Item","ghost-class":"Ghost","tag":"transition-group","animation":200,"move":_vm.onMove},model:{value:(_vm.newOrder),callback:function ($$v) {_vm.newOrder=$$v},expression:"newOrder"}},_vm._l((_vm.newOrder),function(id,i){return _c('player',{key:id,style:({
        'margin-right': i < _vm.newOrder.length - 1 ? '7px' : '0',
      }),attrs:{"player":_vm.getPlayer(id),"index":i}})}),1),_c('div',{staticClass:"d-flex mt-2"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1"},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle-outline")])]}}])},[_c('span',[_vm._v(" Drag to select player order. "),_c('br'),_vm._v("Changes not confirmed until layout control triggered. "),_c('br'),_vm._v("Choose a specific layout, or \"Any Multi\" for automatic fitting of active players. "),_c('br'),_vm._v("Changes time out after 10 seconds. "),_c('br'),_vm._v("AFK players may not be selected, they will automatically be moved to the end on layout trigger if needed. ")])]),_c('v-spacer'),_c('div',[_c('v-btn',{staticClass:"mr-4",attrs:{"fab":"","color":"red","x-small":""},on:{"click":_vm.reset}},[_c('v-icon',[_vm._v("mdi-undo")])],1),_c('v-btn',{staticClass:"mr-1",on:{"click":function($event){return _vm.save('1p')}}},[_vm._v("1P")]),_c('v-btn',{staticClass:"mr-1",on:{"click":function($event){return _vm.save('2p')}}},[_vm._v("2P")]),_c('v-btn',{staticClass:"mr-1",on:{"click":function($event){return _vm.save('4p')}}},[_vm._v("4P")]),_c('v-btn',{staticClass:"mr-1",on:{"click":function($event){return _vm.save('7p')}}},[_vm._v("7P")]),_c('v-btn',{on:{"click":function($event){return _vm.save()}}},[_vm._v("Any Multi")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }