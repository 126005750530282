





















































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import Draggable from 'vuedraggable';
import { API } from '@/types';
import Player from './ViewControl/Player.vue';
import { rootModule } from '@/store';
import { storeNS } from '../../store';
import { apiModify, axiosConfig } from '@/api';
import axios from 'axios';

@Component({
  components: {
    Draggable,
    Player,
  },
})
export default class extends Vue {
  @Prop(Object) readonly playerOrder!: API.PlayerOrder.GET | null;
  @storeNS.State readonly players!: API.Players.GET[];
  newOrder: number[] = [];
  userChanging = false;
  userChangingTO: number | null = null;

  get selectedEvent(): number | null { return rootModule.selectedEvent; }

  get eventPlayerOrder(): API.PlayerOrder.GET['value'] {
    return this.playerOrder?.value || [];
  }

  getPlayer(id: number): API.Players.GET | undefined {
    return this.players.find((p) => p.id === id);
  }

  reset(): void {
    Vue.set(this, 'newOrder', this.eventPlayerOrder);
  }

  @Watch('eventPlayerOrder', { immediate: true })
  onEventPlayerOrderChange(val: number[]): void {
    if (!this.userChanging) {
      Vue.set(this, 'newOrder', val);
    }
  }

  onMove(): void {
    this.userChanging = true;
    if (this.userChangingTO) {
      window.clearTimeout(this.userChangingTO);
    }
    this.userChangingTO = window.setTimeout(() => {
      this.userChanging = false;
      Vue.set(this, 'newOrder', this.eventPlayerOrder);
    }, 10 * 1000);
  }

  async save(view?: '1p' | '2p' | '4p' | '7p'): Promise<void> {
    if (this.selectedEvent) {
      const { data } = await apiModify('playerOrder', this.selectedEvent, { list: this.newOrder });
      this.$emit('playerOrderModified', data);
      await axios.post(
        `/nodecg/show_view/${this.selectedEvent}`,
        { type: view ?? 'multiview' },
        axiosConfig(),
      );
    }
  }
}
