






















import clone from 'clone';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { API } from '@/types';
import { rootModule } from '@/store';
import { apiGETSingle } from '@/api';
import { Socket } from 'vue-socket.io-extended';

@Component
export default class extends Vue {
  @Prop({ type: Array, required: true }) readonly videoPlaylists!: API.Videos.Playlists.GET[];
  @Prop(Object)
    readonly videoPlayerData!: API.Videos.VPlayerData.GET | null;
  availableVideos: API.Videos.Available.GET['value'] = [];

  get selectedEvent(): number | null { return rootModule.selectedEvent; }

  @Watch('selectedEvent', { immediate: true })
  async onSelectedEventChange(): Promise<void> {
    // Reload relevant API data if event happens to change, or on load.
    this.loadAvailableVideosAPIData();
  }

  async loadAvailableVideosAPIData(): Promise<void> {
    if (this.selectedEvent) {
      try {
        const { data } = (await apiGETSingle('availableVideos', this.selectedEvent));
        Vue.set(this, 'availableVideos', data.value || []);
      } catch (err) {
        Vue.set(this, 'availableVideos', []);
      }
    }
  }

  @Socket('availableVideosModified')
  scktAvailVidsModified(val: API.Videos.Available.GET | null): void {
    if (this.selectedEvent === val?.eventId) {
      Vue.set(this, 'availableVideos', { ...this.availableVideos, ...val.value });
    }
  }

  get playlist(): API.Videos.Playlists.GET | undefined {
    return this.videoPlaylists.find((p) => p.id === this.playerData.video?.playlistId);
  }

  get playerData(): API.Videos.VPlayerData.GET['value'] {
    return clone(this.videoPlayerData?.value || { playing: false, video: null });
  }

  get videos(): API.Videos.Available.GET['value'] {
    return this.availableVideos || [];
  }

  get currentVideoName(): string {
    return this.videos.find((v) => (
      v.sum === this.playerData.video?.sum))?.name || '?';
  }

  get currentVideoPlaylistPos(): number {
    return (this.playlist?.value || []).findIndex((v) => v.id === this.playerData.video?.id);
  }

  get currentVideoProgress(): string {
    return `${this.playerData.video?.position || '0'}s/${
      this.playerData.video?.duration || '?'}s`;
  }

  get seperator(): string {
    return 'thin solid rgba(255, 255, 255, 0.12)';
  }
}
