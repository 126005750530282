
































import { Vue, Component, Prop } from 'vue-property-decorator';
import Draggable from 'vuedraggable';
import { API } from '@/types';

@Component({
  components: {
    Draggable,
  },
})
export default class extends Vue {
  @Prop({ type: Number, required: true }) readonly index!: number;
  @Prop(Object) readonly player!: API.Players.GET | undefined;

  get name(): string {
    return this.player?.user?.name || '?';
  }

  get isActive(): boolean {
    return this.player?.active ?? false;
  }
}
