






import { Vue, Component, Prop } from 'vue-property-decorator';
import { API } from '@/types';
import { rootModule } from '@/store';
import axios from 'axios';
import { axiosConfig } from '@/api';

@Component
export default class extends Vue {
  @Prop(Object) readonly player!: API.Players.GET;
  @Prop(Boolean) multi!: boolean;

  get selectedEvent(): number | null { return rootModule.selectedEvent; }

  get name(): string {
    return this.player.user?.name || '?';
  }

  async showView(): Promise<void> {
    if (this.selectedEvent) {
      await axios.post(
        `/nodecg/show_view/${this.selectedEvent}`,
        { playerId: this.player.id, type: this.multi ? 'multiview' : '1p' },
        axiosConfig(),
      );
    }
  }
}
