












































import clone from 'clone';
import { Vue, Component, Watch } from 'vue-property-decorator';
import { API } from '@/types';
import { formatCurrencyStr, msToTimeStr } from '@/util';
import { rootModule } from '@/store';
import { Socket } from 'vue-socket.io-extended';
import { apiGETMulti, apiGETSingle } from '@/api';

@Component
export default class extends Vue {
  players: API.Players.GET[] = [];
  leaderboardPlacements: API.LeaderboardPlacements.GET[] = [];
  event: API.Events.GET | null = null;
  msToTimeStr = msToTimeStr;

  get selectedEvent(): number | null { return rootModule.selectedEvent; }

  @Watch('selectedEvent', { immediate: true })
  async onSelectedEventChange(): Promise<void> {
    // Reload relevant API data if event happens to change, or on load.
    this.loadAPIData();
  }

  async loadAPIData(): Promise<void> {
    if (this.selectedEvent) {
      Vue.set(this, 'players', (await apiGETMulti('players', {
        offset: 0,
        embed: ['user'],
        eventId: this.selectedEvent,
      }, true)).data);
      Vue.set(this, 'leaderboardPlacements', (await apiGETMulti('leaderboardPlacements', {
        offset: 0,
        eventId: this.selectedEvent,
      }, true)).data);
      Vue.set(this, 'event', (await apiGETSingle('events', this.selectedEvent)).data);
    }
  }

  @Socket('playerModified')
  socketPlayerModified(newVal: API.Players.GET | null, oldVal: API.Players.GET | null): void {
    const index = this.players.findIndex((i) => i.id === oldVal?.id);
    if (index >= 0 && newVal && oldVal) {
      if (oldVal.userId !== newVal.userId || oldVal.eventId !== newVal.eventId) {
        // Force a reload if the embedded user/event needs updating.
        this.loadAPIData();
      } else {
        Vue.set(this.players, index, { ...this.players[index], ...newVal });
      }
    }
    if (!newVal || !oldVal) {
      this.loadAPIData(); // Force a reload if an entry has been added or deleted.
    }
  }

  // Updates the embedded users if currently in a loaded player entity.
  @Socket('userModified')
  sockerUserModified(newVal: API.Users.GET | null, oldVal: API.Users.GET | null): void {
    this.players.forEach((player, i) => {
      if (newVal && oldVal && player.userId === oldVal.id) {
        Vue.set(this.players[i], 'user', { ...this.players[i].user, ...newVal });
      }
    });
  }

  @Socket('eventModified')
  socketEventModified(val: API.Events.GET | null): void {
    if (this.event?.id === val?.id) {
      Vue.set(this, 'event', { ...this.event, ...val });
    }
  }

  @Socket('leaderboardPlacementModified')
  socketLeaderboardPlacementModified(
    newVal: API.LeaderboardPlacements.GET | null,
    oldVal: API.LeaderboardPlacements.GET | null,
  ): void {
    const index = this.leaderboardPlacements.findIndex((p) => p.playerId === oldVal?.playerId);
    if (newVal && oldVal && index >= 0) {
      Vue.set(
        this.leaderboardPlacements,
        index,
        { ...this.leaderboardPlacements[index], ...newVal },
      );
    }
    if (!newVal && index >= 0) {
      this.leaderboardPlacements.splice(index, 1);
    }
    if (newVal && !oldVal) {
      this.leaderboardPlacements.unshift(newVal);
    }
  }

  get formattedLeaderboard(): {
    id: number; name: string; time: number | null; additionalData: { [k: string]: unknown } | null;
  }[] {
    return clone(this.players)
      .map((p) => {
        const bestRunEvent = this.leaderboardPlacements.find((pl) => pl.playerId === p.id);
        return {
          id: p.id,
          name: p.user?.name || '?',
          time: bestRunEvent?.time ?? Number.MAX_SAFE_INTEGER,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          additionalData: (bestRunEvent?.additionalData as any) ?? null,
        };
      })
      // .filter((p) => p.time < Number.MAX_SAFE_INTEGER)
      .sort((a, b) => {
        if (this.event?.altLbCalculation && a.additionalData && b.additionalData
        && a.additionalData.lvlIndex !== b.additionalData.lvlIndex) {
          return b.additionalData.lvlIndex - a.additionalData.lvlIndex;
        }
        return a.time - b.time;
      })
      .map((p) => ({ ...p, ...{ time: p.time === Number.MAX_SAFE_INTEGER ? null : p.time } }));
  }

  get prizePool(): string[] {
    return (this.event?.prizes || []).map((p) => formatCurrencyStr(this.event?.prizeCurrency, p));
  }
}
